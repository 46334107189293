import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const JoeRapper = () => {

  return (
    <Layout>
      <SEO title="Local Talent: J/O/E" />


    </Layout>
  )
}

export default JoeRapper